<template>
  <section class="container-fluid mt-4 admin-dashboard">
    <div class="box">
      <div class="box-header d-flex justify-content-between bg-white">
        <h3>อัพโหลด ผู้ใช้ PEnO .xlsx</h3>
      </div>
      <div class="box-body">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="option"
            id="option_add"
            value="add"
            v-model="option"
          />
          <label class="form-check-label" for="option_add"> Add </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="option"
            id="option_replace"
            value="replace"
            v-model="option"
          />
          <label class="form-check-label" for="option_replace"> Replace </label>
        </div>
        <div class="custom-file mt-3">
          <input type="file" class="custom-file-input" id="file" ref="file" />
          <label class="custom-file-label" for="file">เลือกไฟล์</label>
        </div>
        <div class="text-danger">
          {{ errorMessage }}
        </div>
        <div class="form-group d-flex justify-content-between">
          <div class="text-success">
            {{ successMessage }}
          </div>
          <button
            class="mt-4 btn btn-action py-1 px-4"
            v-promise-btn
            @click="importPeno"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { get } from 'lodash';

export default {
  name: 'PenoImport',

  data() {
    return {
      option: 'add',
      errorMessage: null,
      successMessage: null,
    };
  },
  methods: {
    ...mapMutations(['setNationalPlan', 'setLatestNationalPlan']),
    importPeno() {
      const files = this.$refs.file.files;
      if (!files.length) return;
      var data = new FormData();
      data.append('option', this.option);
      data.append('file', files[0]);
      return window.axios
        .post('/api/peno/import', data)
        .then(({ data }) => {
          this.successMessage = `${data.count} user created`;
        })
        .catch((err) => {
          this.errorMessage = get(err, 'response.data.errors.file[0]', '');
          console.log(errorMessage);
        });
    },
  },
};
</script>
